/* ############################################## 

Código loader Spinner Puntaje fuera de la aplicación

############################################## */

.outside-loader {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 60px;
    height: 60px;
}

.spinner {
    margin: 0 auto;
    font-size: 8px;
    position: relative;
    text-indent: -9999em;
    border-top: 1.1em solid rgba(169, 169, 169, 0.2);
    border-right: 1.1em solid rgba(169, 169, 169, 0.2);
    border-bottom: 1.1em solid rgba(169, 169, 169, 0.2);
    border-left: 1.1em solid #3399ff;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: spin 1.1s infinite linear, color-puntaje-outside 3s infinite linear;
    animation: spin 1.1s infinite linear, color-puntaje-outside 3s infinite linear;
}
.spinner,
.spinner:after {
    border-radius: 50%;
    width: 60px;
    height: 60px;
}
@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-webkit-keyframes color-puntaje-outside {
    0% {
        border-left: 1.1em solid #3399ff;
    }
    20% {
        border-left: 1.1em solid #9c3;
    }
    40% {
        border-left: 1.1em solid #fc0;
    }
    60% {
        border-left: 1.1em solid #f90;
    }
    80% {
        border-left: 1.1em solid #f33;
    }
    100% {
        border-left: 1.1em solid #3399ff;
    }
}
@keyframes color-puntaje-outside {
    0% {
        border-left: 1.1em solid #3399ff;
    }
    20% {
        border-left: 1.1em solid #9c3;
    }
    40% {
        border-left: 1.1em solid #fc0;
    }
    60% {
        border-left: 1.1em solid #f90;
    }
    80% {
        border-left: 1.1em solid #f33;
    }
    100% {
        border-left: 1.1em solid #3399ff;
    }
}
