@import "style.commons.scss";

//Estilo de Puntaje

//########## Paleta de colores ##########
// Fondo estudiantes
$background-color-profile1: #f7f7f7;
// Fondo profesores
$background-color-profile2: #f7f7f7;
// Navbar
$background-color-navbar-profile1: #0060c7; //#796ad9;
$font-color-navbar-profile1: white;
$font-color-hover-navbar-profile1: white;
// Titulo principal

// Lista de asignaturas
$asignatura-list: "lectura", "redaccion", "matematica", "ingles";
//Colores de las asignaturas
$asignatura-colors: (
    "lectura": #add8e6,
    "redaccion": #b95900,
    "matematica": #0071f1,
    "ingles": #007a3f
);
$asignatura-font-colors: (
    "lectura": #550e13,
    "redaccion": #5a3200,
    "matematica": #004d77,
    "ingles": #002c23
);
// Paleta de colores
$palette-colors: (
    "primary": #0060c7,
    //#796ad9
    "primario": #0060c7,
    //#796ad9
    "background": #f7f7f7,
    "fontColor": #1a1a1a,
    "fontColorOverSecondary": white,
    "lighterFontColor": #595959,
    "noticias": #7faf5b,
    "establecimientos": #309fb2,
    "darkBackground": #221f1f,
    "secondary": #a322f2,
    "tertiary": #8dd800,
    "quaternary": #3a913f,
    "accent": #fedb00,
    "facebook": #3c5a99,
    "instagram": #c13584,
    "twitter": #38a1f3,
    "linkedin": #0077b5,
    "youtube": #ff0000,
    "google": #4264f4
);

$color-correctas: #7e9f3e;

$responsive-toggle-button-background-color: transparent;
$ensayo_resumen_background_color_puntos: #0060c7; //#796ad9;
$ensayo_resumen_font_color_puntos: white;

// Tamaños
$footer-push-height: 460px;

// Fonts
$custom-font-family1: "Roboto";
$custom-font-family2: "Open Sans";
$custom-font-family3: "Karla";

$base-titlebar_color: #0060c7;
$base-titlebar_subtitle_color: #0060c7;

//Bodies
@mixin body_default {
    background-color: $background-color-profile1;
    background-repeat: repeat;
    font-family: var(--custom-font-family2), Sans-Serif;
}
@mixin body_profile1 {
    background-color: $background-color-profile1;
    background-repeat: repeat;
    font-family: var(--custom-font-family2), Sans-Serif;
}
@mixin body_profile2 {
    background-color: $background-color-profile1;
    background-repeat: repeat;
    font-family: var(--custom-font-family2), Sans-Serif;
}
@mixin body_profile3 {
    background-color: $background-color-profile1;
    background-repeat: repeat;
    font-family: var(--custom-font-family2), Sans-Serif;
}

//Botones
//Botón amarillo, normal
@mixin btn_base_1 {
    @include border-radius(30px);
    @include fade-transition(0.2s);
    padding: 8px 16px;
    background: #0056b5;
    border: none;
    color: white;
    font-weight: 300;
    font-family: var(--custom-font-family1), Sans-Serif;
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
    &:hover {
        color: white;
        text-decoration: underline;
    }
    &:focus,
    &:active {
        background: #0d3663;
        color: white;
        box-shadow: none;
    }

    &:active:focus {
        transform: scale(0.9);
        box-shadow: none;
    }
}

//Botón gris, para cancelar
@mixin btn_base_2 {
    @include border-radius(30px);
    @include fade-transition(0.2s);
    padding: 8px 16px;
    background: #bfbfbf;
    color: #444;
    border: none;
    font-weight: 300;
    font-family: var(--custom-font-family1), Sans-Serif;
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
    &:hover {
        background: #bfbfbf;
        color: #000000;
    }
    &:focus,
    &:active {
        background: #636363;
        color: #e6e6e6;
    }
    &:active:focus {
        transform: scale(0.9);
    }
}

//Botón verde, para aceptar
@mixin btn_base_3 {
    @include border-radius(30px);
    @include fade-transition(0.2s);
    padding: 8px 16px;
    background: #0060c7;
    border: none;
    color: white;
    font-weight: 300;
    font-family: var(--custom-font-family1), Sans-Serif;
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
    &:hover {
        color: white;
        text-decoration: underline;
    }
    &:focus,
    &:active {
        background: #0060c7;
        color: white;
        box-shadow: none;
    }
    &:active:focus {
        transform: scale(0.9);
        box-shadow: none;
    }
}

@mixin btn_base_4 {
    @include border-radius(30px);
    @include fade-transition(0.2s);
    padding: 8px 16px;
    background: white;
    border: 1px solid #0060c7; //#796ad9;
    color: #0060c7; //#796ad9;
    font-weight: 300;
    font-family: var(--custom-font-family1), Sans-Serif;
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
    &:hover {
        color: #0060c7; //#796ad9;
        text-decoration: underline;
    }
    &:focus,
    &:active,
    &.active {
        background: #0060c7; //#796ad9;
        border: 1px solid #0060c7; //#796ad9;
        color: white;
        box-shadow: none;
    }
    &:active:focus {
        transform: scale(0.9);
        box-shadow: none;
    }
}

//Botones pequeños redes sociales
@mixin small-round-social-btn {
    background-color: #336699;
    @include border-radius(0px);
    @include fade-transition(0.1s);
    display: block;
    line-height: 20px;
    width: 20px;
    height: 20px;
    display: table;
    table-layout: fixed;
    overflow: hidden;
    cursor: pointer;
    opacity: 1;
    @include fade-transition(0.1s);
    &:hover {
        opacity: 0.8;
    }
    .small-round-btn-inner {
        display: table-row;
    }
    a {
        display: table-cell;
        vertical-align: middle;
        text-align: center;
    }
}

//Botones pequeños de las tablas
@mixin small-table-btn-with-only-icon {
    @include fade-transition(0.1s);
    background: map-get($palette-colors, "primary");
    color: white;
    text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.3);
    border: none;
    padding: 6px;
    width: 26px;
    height: 26px;
    line-height: 14px;
    font-size: 14px;
    &:hover {
        background: darken(map-get($palette-colors, "primary"), 10%);
    }
    &:focus {
        color: white;
    }
}

//Títulos
//Título con franja de pergamino
@mixin custom_title_1 {
    padding: 12px 20px 18px 20px;
    color: #4e5457;
    background: url(/public/img/h2.png) no-repeat;
    background-size: 100% 100%;
    max-width: 481px;
    height: 86px;
    text-align: center;
    margin: 30px auto 20px auto;
    h2 {
        margin: 0;
        font-weight: normal;
        line-height: 32px;
        text-align: center;
        font-family: var(--custom-font-family1);
        font-size: 32px;
    }
}

@mixin h3_sub_title_base($key-color: map-get($palette-colors, "primary")) {
    margin: 0;
    padding: 0;
    font-size: 17px;
    color: var(--base-titlebar_subtitle_color);
    width: 100%;
    font-weight: 400;
    padding-bottom: 15px;
    margin-bottom: 10px;
    border-bottom: 1px solid #e6e6e6;
    font-family: var(--custom-font-family1), Sans-Serif;
}

// Estos titulos debería deprecarlos de a poco, son arrastre de puntaje
@mixin h3_sub_title_1 {
    @include h3_sub_title_base();
}

@mixin h3_sub_title_2 {
    @include h3_sub_title_base();
}

@mixin h3_sub_title_3 {
    @include h3_sub_title_base();
}

//Sub título bold
@mixin h4_sub_title_1 {
    font-weight: 700;
    font-size: 14px;
}

//Tablas
@mixin simple_table_statistics {
    tbody > tr > td,
    tbody > tr > th,
    thead > tr > th {
        padding: 4px 6px;
        vertical-align: middle;
    }
    tbody > tr > td,
    tbody > tr > th {
        border: none;
    }
    tbody > tr > td,
    thead > tr > th {
        text-align: center;
    }
    tbody > tr:nth-of-type(odd) {
        background-color: #e9f5d2;
    }
    thead > tr > th {
        border-bottom: 2px solid #c0d696;
    }
    tr > th {
        //background-color: #cde2a5;
    }
    thead > tr > th:first-child,
    tbody > tr > th:first-child {
        //border-right: 2px solid white;
        text-align: left;
    }
}

@mixin table_instruments {
    thead {
        //border-bottom: 3px solid #efcd67;
    }
    thead > tr > th {
        padding: 8px 6px;
        font-size: 13px;
        font-weight: 500;
        text-align: left;
        vertical-align: middle;
        border: 2px solid white;
        border-top: none;
        border-bottom: none;
        //background-color: #d1f1af;
        //color: #436b17;
        background-color: #fff4d2;
        color: #663300;
        &:first-child {
            border-left: none;
            border-radius: 6px 0 0 0;
        }
        &:last-child {
            border-right: none;
            border-radius: 0 6px 0 0;
        }
        &.actions {
            width: 1%;
        }
    }
    tbody > tr > td {
        padding: 5px;
        font-size: 12px;
        text-align: left;
        vertical-align: middle;
        border: none;
        &.actions {
            white-space: nowrap;
            overflow: hidden;
            text-align: center;
        }
    }
    tbody > tr:nth-of-type(even) {
        background-color: #f1f1f1;
    }
    tbody > tr > td.min-size,
    thead > tr > th.min-size {
        width: 1%;
        text-align: center;
    }
    tbody > tr > th.acciones,
    tbody > tr > td.acciones {
        width: 1%;
        text-align: center;
    }
    tbody > tr > td.correcta {
        background-color: #d5eca9;
    }
    &.color-scheme-1 {
        thead > tr > th {
            background-color: #fff4d2;
            color: #663300;
        }
    }
    &.color-scheme-2 {
        thead > tr > th {
            background-color: #d6d6d6;
            color: #5f5f5f;
        }
    }
}

//Carousel
@mixin carousel_style_1 {
    padding-bottom: 30px;
    .sp-controllers {
        bottom: 0px;
    }
    @media screen and (min-width: 700px) {
        .sp-arrow-left {
            left: 50%;
            margin-left: -260px;
        }
        .sp-arrow-right {
            right: 50%;
            margin-right: -260px;
        }
    }
    .circle {
        width: 30px;
        height: 30px;
        text-align: center;
        line-height: 30px;
        border: none;
        background-color: #ffcc33;
        color: #663300;
        @include fade-transition(0.1s);
        &:hover {
            background-color: #ffe38e;
        }
        span.circle-number {
            font-size: 16px;
            display: block;
        }
    }
    .selected-circle {
        background-color: #ffe38e;
    }
    .sp-slider > li h4 {
        display: inline-block;
        font-family: var(--custom-font-family1), Sans-Serif;
        font-size: 24px;
        margin-top: 10px;
        padding: 3px 5px;
        margin-bottom: 10px;
        color: #333;
        max-width: 440px;
        background: rgba(256, 256, 256, 0.4);
    }
    @media screen and (max-width: 500px) {
        .sp-slider > li h4 {
            font-size: 18px;
        }
    }
    .sp-slider > li img {
        padding: 10px 0 0 0;
    }
}

//Ribbon
@mixin custom_title_2 {
    position: relative;
    z-index: 1;
    text-align: center;
    .ribbon {
        font-weight: normal;
        font-size: 26px;
        //width: 50%;
        display: inline-block;
        max-width: 90%;
        min-width: 30%;
        font-family: var(--custom-font-family1), Sans-Serif;
        text-shadow: 1px 1px rgba(0, 0, 0, 0.4);
        position: relative;
        background: #8f7047;
        color: #fff;
        text-align: center;
        padding: 0.5em 1em; /* Adjust to suit */
        margin: 1em auto 1em;
    }
    .ribbon:before,
    .ribbon:after {
        content: "";
        position: absolute;
        display: block;
        bottom: -0.5em;
        border: 1em solid #b59069;
        z-index: -1;
    }
    .ribbon:before {
        left: -2em;
        border-right-width: 2em;
        border-left-color: transparent;
    }
    .ribbon:after {
        right: -2em;
        border-left-width: 2em;
        border-right-color: transparent;
    }
    .ribbon .ribbon-content:before,
    .ribbon .ribbon-content:after {
        content: "";
        position: absolute;
        display: block;
        border-style: solid;
        border-color: #96714b transparent transparent transparent;
        bottom: -0.5em;
    }
    .ribbon .ribbon-content:before {
        left: 0;
        border-width: 0.5em 0 0 1em;
    }
    .ribbon .ribbon-content:after {
        right: 0;
        border-width: 0.5em 1em 0 0;
    }
}

//Showcase Boxes
@mixin showcasebox_style_1 {
    @include showcasebox_style_1_with_primary_color(map-get($palette-colors, "primary"));
}

@mixin showcasebox_style_1_with_primary_color($key-color: #ffffff) {
    .showcasebox {
        border: 1px solid #e6e6e6;
        @include border-radius(4px);
        background-color: white;
        box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
        .showcasebox_title {
            padding: 12px 20px;
            border-bottom: 1px solid #e6e6e6;
            margin-bottom: 10px;
            .showcasebox_title_inner {
                display: flex;
                align-items: center;
                justify-content: space-between;
                .showcasebox_title_icon {
                    img {
                        height: 40px;
                        width: auto;
                        margin-left: 5px;
                    }
                    .icon-container {
                        width: 40px;
                        height: 40px;
                        background-color: $key-color;
                        margin-right: 8px;
                        border-radius: 50%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        .icon {
                            font-size: 22px;
                            line-height: 22px;
                            color: white;
                        }
                    }
                }
                .showcasebox_title_text {
                    font-family: var(--custom-font-family1), Sans-Serif;
                    font-size: 18px;
                    color: $key-color; //#796ad9;
                }
                .showcasebox_data_not_found {
                }
                .showcasebox_view_more {
                }
            }
        }
        .showcasebox_content {
            background-color: transparent;
            overflow: hidden;
            padding: 12px 20px;
            .info_text {
                color: #1a1a1a;
                margin-bottom: 0;
            }
        }
    }
}

@mixin showcasebox_style_2 {
    .showcasebox {
        box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
        background-color: white;
        border-radius: 3px;
        overflow: hidden;
        padding: 8px;
    }
}

@mixin showcasebox_style_user {
    $avatar_size: 80px;
    .btn_style {
        font-size: 12px;
    }
    .left_column {
        float: left;
        padding-right: 5px;
    }
    .right_column {
        margin-left: $avatar_size + 5px;
        padding-left: 5px;
    }
    .user-circle-container {
        background-color: #5fb9e6;
        color: white;
        border-radius: 50%;
        overflow: hidden;
        text-align: center;
        padding: 0;
        min-height: auto;
        height: $avatar_size;
        width: $avatar_size;
        .fa-placeholder .fa {
            font-size: $avatar_size;
            padding-top: 6px;
        }
        img.avatar {
            width: 100%;
            height: 100%;
        }
    }
    h4 {
        font-family: var(--custom-font-family1), Sans-Serif;
        font-size: 20px;
        border-bottom: 2px solid map-get($palette-colors, "primary");
        width: 100%;
        padding-bottom: 5px;
        margin-bottom: 10px;
        color: #5b5b5f;
    }
    .email {
        margin-bottom: 15px;
        color: #5b5b5f;
        fa {
            color: #5b5b5f;
            font-size: 110%;
            opacity: 0.4;
        }
    }
    .profile-link {
        display: inline-block;
        margin-bottom: 8px;
        font-size: 13px;
        &:hover {
            text-decoration: none;
        }
    }
    .edit-link {
        display: inline-block;
        font-size: 13px;
        &:hover {
            text-decoration: none;
        }
    }
    .no-email {
        margin-bottom: 15px;
        color: #848484;
        font-size: 90%;
        fa {
            color: #f7cb43;
            font-size: 110%;
        }
    }
    .clear-btn {
        margin-bottom: 5px;
    }
}

// Menu item box
$menu_item_box_icon_size: 90px;
$menu_item_box_icon_margin: 14px;
$menu_item_box_icon_size_small: 60px;
$menu_item_box_icon_margin_small: 8px;
$menu_item_box_icon_response_break: 500px;
@mixin menu_item_box_default_style {
    @include border-radius(3px);
    background-color: white;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
    margin-bottom: 30px;
    position: relative;
    @media screen and (max-width: $menu_item_box_icon_response_break) {
        margin-bottom: 20px;
    }
    padding: 10px;
    display: block;
    color: #333;
    &:hover {
        text-decoration: none;
    }
    &:focus {
        outline: none;
        text-decoration: none;
    }
    &:active {
        box-shadow: inset 1px 1px 1px rgba(0, 0, 0, 0.1);
    }
    .menu_item_box_wrapper {
        display: table;
        width: 100%;
        table-layout: fixed;
    }
    .menu_item_row_wrapper {
        display: table-row;
    }
    .menu_item_box_icon_container {
        display: table-cell;
        width: $menu_item_box_icon_size + 10px;
        vertical-align: top;
        padding-right: 10px;
        @media screen and (max-width: $menu_item_box_icon_response_break) {
            width: $menu_item_box_icon_size_small + 10px;
        }
        .menu_item_box_icon {
            @include border-radius(50%);
            background-color: #f1f1f1;
            overflow: hidden;
            width: $menu_item_box_icon_size;
            height: $menu_item_box_icon_size;
            text-align: center;
            .icono {
                font-size: $menu_item_box_icon_size - ($menu_item_box_icon_margin * 2);
                margin-top: $menu_item_box_icon_margin;
                color: silver;
            }
            @media screen and (max-width: $menu_item_box_icon_response_break) {
                width: $menu_item_box_icon_size_small;
                height: $menu_item_box_icon_size_small;
                .icono {
                    font-size: $menu_item_box_icon_size_small - ($menu_item_box_icon_margin_small * 2);
                    margin-top: $menu_item_box_icon_margin_small;
                }
            }
        }
    }
    .menu_item_box_body {
        display: table-cell;
        vertical-align: top;
        padding-bottom: 40px;
        position: relative;
        .menu_item_box_title {
            margin: 0 0 10px;
            @include h3_sub_title_base(#9c3);
            display: inline-block;
            padding-bottom: 5px;
            margin-bottom: 5px;
            width: auto;
        }
        .menu_item_box_text {
            color: #898989;
        }
    }
    .menu_item_box_link {
        position: absolute;
        bottom: 10px;
        text-align: right;
        right: 10px;
        line-height: 26px;
        .arrow {
            font-size: 20px;
            vertical-align: middle;
            margin-left: 5px;
            color: #9c3;
        }
    }
    &.locked {
        cursor: default;
        .menu_item_box_wrapper,
        .menu_item_box_link {
            filter: blur(2px);
        }
        .locked-menu-item {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            padding: 8px;
            @include border-radius(3px 3px 0 0);
            background-color: rgba(39, 145, 197, 0.7);
            color: white;
            text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.15);
            .padlock {
                font-size: 30px;
                float: left;
                margin-right: 10px;
            }
            .locked-info {
                margin: 0;
                margin-left: 30px;
                display: block;
            }
        }
    }
}

// Modales

@mixin modal_style {
    .modal-content {
        //@include border-radius(0);
        border: none;
        //box-shadow: 0 1px 3px rgba(0,0,0,.5);
        .modal-header {
            border-bottom: none;
            .close {
                font-size: 34px;
                margin-top: -4px;
            }
            .modal-title {
                color: #000000;
                font-family: var(--custom-font-family1), Sans-Serif;
            }
        }
    }
}

//Estilos adicionales (provisorio)
@mixin landing_menu_top {
    ul.nav > li > a {
        color: $font-color-navbar-profile1;
        &:hover {
            background-color: rgba($font-color-navbar-profile1, 0.1);
            color: $font-color-navbar-profile1;
        }
    }
}

@mixin numero_pregunta_style {
    background-color: map-get($palette-colors, "primary");
    color: white;
    @include border-radius(0);
    border: none;
    line-height: 32px;
    box-shadow: none;
}

@mixin menu_asignaturas_platform_style {
    ul > li.current-asignatura > a {
        color: white !important;
        & > .icono {
            color: white !important;
        }
    }
    ul > li.inactive > a:hover {
        color: white !important;
        & > .icono {
            color: white !important;
        }
    }

    &.dropdownMode {
        ul > li.current-asignatura > a {
            color: inherit !important;
            & > .icono {
                color: inherit !important;
            }
        }
        ul > li.current-asignatura > a:hover,
        &.current-asignatura:focus {
            color: white !important;
            & > .icono {
                color: white !important;
            }
        }
    }
}
