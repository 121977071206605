// Roboto y Open Sans
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i|Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i&display=swap");

//Karla
@import url("https://fonts.googleapis.com/css?family=Karla:400,400i,700,700i&display=swap");

/*!
 *  Apricot v3.4.0
 */ /*
@font-face {
    font-family: "CB Icons";
    src: url("./assets/fonts/5d02e08cb4d157c28d4c47e9106e67b3-cb-icons.eot");
    src: url("./assets/fonts/5d02e08cb4d157c28d4c47e9106e67b3-cb-icons.eot") format("embedded-opentype"),
        url("./assets/fonts/5d02e08cb4d157c28d4c47e9106e67b3-cb-icons.woff") format("woff"),
        url("./assets/fonts/5d02e08cb4d157c28d4c47e9106e67b3-cb-icons.ttf") format("truetype"),
        url("./assets/fonts/5d02e08cb4d157c28d4c47e9106e67b3-cb-icons.svg") format("svg");
}
[class^="cb-icon-"],
[class*=" cb-icon-"],
.glyphicon-cb,
.cb-glyph:not(.cb-glyph-numeric) {
    font-family: "CB Icons";
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.cb-glyph.cb-glyph-xs,
.cb-glyph-numeric.cb-glyph-xs {
    font-size: 1rem;
}
.cb-glyph.cb-glyph-sm,
.cb-glyph-numeric.cb-glyph-sm {
    font-size: -webkit-calc(1rem * 2) !important;
    font-size: calc(1rem * 2) !important;
}
.cb-glyph.cb-glyph-md,
.cb-glyph-numeric.cb-glyph-md {
    font-size: -webkit-calc(1rem * 3) !important;
    font-size: calc(1rem * 3) !important;
}
.cb-glyph.cb-glyph-lg,
.cb-glyph-numeric.cb-glyph-lg {
    font-size: -webkit-calc(1rem * 4) !important;
    font-size: calc(1rem * 4) !important;
}
.cb-glyph.cb-glyph-xl,
.cb-glyph-numeric.cb-glyph-xl {
    font-size: -webkit-calc(1rem * 6) !important;
    font-size: calc(1rem * 6) !important;
}
.cb-glyph.cb-glyph-corporate,
.cb-glyph-numeric.cb-glyph-corporate {
    color: #006298;
}
.cb-glyph.cb-glyph-corporate.cb-glyph-numeric,
.cb-glyph-numeric.cb-glyph-corporate.cb-glyph-numeric {
    border-color: #006298;
}
.cb-glyph.cb-glyph-corporate.cb-glyph-numeric.cb-glyph-circular,
.cb-glyph-numeric.cb-glyph-corporate.cb-glyph-numeric.cb-glyph-circular {
    color: #ffffff;
    border-color: #006298;
    background-color: #006298;
}
.cb-glyph.cb-glyph-corporate2,
.cb-glyph-numeric.cb-glyph-corporate2 {
    color: #0077c8;
}
.cb-glyph.cb-glyph-corporate2.cb-glyph-numeric,
.cb-glyph-numeric.cb-glyph-corporate2.cb-glyph-numeric {
    border-color: #0077c8;
}
.cb-glyph.cb-glyph-corporate2.cb-glyph-numeric.cb-glyph-circular,
.cb-glyph-numeric.cb-glyph-corporate2.cb-glyph-numeric.cb-glyph-circular {
    color: #ffffff;
    border-color: #0077c8;
    background-color: #0077c8;
}
.cb-glyph.cb-glyph-k-12,
.cb-glyph-numeric.cb-glyph-k-12 {
    color: #009cde;
}
.cb-glyph.cb-glyph-k-12.cb-glyph-numeric,
.cb-glyph-numeric.cb-glyph-k-12.cb-glyph-numeric {
    border-color: #009cde;
}
.cb-glyph.cb-glyph-k-12.cb-glyph-numeric.cb-glyph-circular,
.cb-glyph-numeric.cb-glyph-k-12.cb-glyph-numeric.cb-glyph-circular {
    color: #ffffff;
    border-color: #009cde;
    background-color: #009cde;
}
.cb-glyph.cb-glyph-higher-education,
.cb-glyph-numeric.cb-glyph-higher-education {
    color: #702f8a;
}
.cb-glyph.cb-glyph-higher-education.cb-glyph-numeric,
.cb-glyph-numeric.cb-glyph-higher-education.cb-glyph-numeric {
    border-color: #702f8a;
}
.cb-glyph.cb-glyph-higher-education.cb-glyph-numeric.cb-glyph-circular,
.cb-glyph-numeric.cb-glyph-higher-education.cb-glyph-numeric.cb-glyph-circular {
    color: #ffffff;
    border-color: #702f8a;
    background-color: #702f8a;
}
.cb-glyph.cb-glyph-access,
.cb-glyph-numeric.cb-glyph-access {
    color: #3a913f;
}
.cb-glyph.cb-glyph-access.cb-glyph-numeric,
.cb-glyph-numeric.cb-glyph-access.cb-glyph-numeric {
    border-color: #3a913f;
}
.cb-glyph.cb-glyph-access.cb-glyph-numeric.cb-glyph-circular,
.cb-glyph-numeric.cb-glyph-access.cb-glyph-numeric.cb-glyph-circular {
    color: #ffffff;
    border-color: #3a913f;
    background-color: #3a913f;
}
.cb-glyph-circular:not(.cb-glyph-numeric) {
    position: relative;
    display: block;
    height: 2em;
    width: 2em;
}
.cb-glyph-circular:not(.cb-glyph-numeric)::before {
    position: absolute;
    z-index: 2;
    left: -webkit-calc(1em / 2);
    left: calc(1em / 2);
    top: -webkit-calc(1em / 2);
    top: calc(1em / 2);
    color: #ffffff;
}
.cb-glyph-circular:not(.cb-glyph-numeric)::after {
    content: " ";
    position: absolute;
    z-index: 1;
    display: block;
    border-radius: 50%;
    height: 2em;
    width: 2em;
    background-color: #009cde;
}
.cb-glyph-circular:not(.cb-glyph-numeric).cb-glyph-corporate::after {
    background-color: #006298;
}
.cb-glyph-circular:not(.cb-glyph-numeric).cb-glyph-corporate2::after {
    background-color: #0077c8;
}
.cb-glyph-circular:not(.cb-glyph-numeric).cb-glyph-k-12::after {
    background-color: #009cde;
}
.cb-glyph-circular:not(.cb-glyph-numeric).cb-glyph-higher-education::after {
    background-color: #702f8a;
}
.cb-glyph-circular:not(.cb-glyph-numeric).cb-glyph-access::after {
    background-color: #3a913f;
}
.cb-icon-icn_online-tool::before,
.cb-online-tool::before {
    content: "\e936";
}
.cb-icon-icn_download::before,
.cb-icon-icn_download_med::before,
.cb-icon-icn_download_small::before,
.cb-icon-icn_download_res::before,
.cb-download::before {
    content: "\e914";
}
.cb-icon-icn_download_bulk::before,
.cb-batch-download::before {
    content: "\e902";
}
.cb-icon-icn_local-menu::before,
.cb-menu-local::before {
    content: "\e925";
}
.cb-icon-icn_filter::before,
.cb-filter::before {
    content: "\e91d";
}
.cb-icon-icn_sort::before,
.cb-sort::before {
    content: "\e947";
}
.cb-sort-asc::before {
    content: "\e950";
}
.cb-sort-desc::before {
    content: "\e913";
}
.cb-icon-icn_arrow-up::before,
.cb-icon-double_arrow_round::before,
.cb-icon-double_arrow::before,
.cb-up::before {
    content: "\e950";
}
.cb-icon-icn_arrow-down::before,
.cb-down::before {
    content: "\e913";
}
.cb-icon-icn_arrow-left::before,
.cb-left::before {
    content: "\e921";
}
.cb-icon-icn_arrow-right::before,
.cb-right::before {
    content: "\e940";
}
.cb-icon-icn_double-left-arrow::before,
.cb-dbl-left::before {
    content: "\e910";
}
.cb-icon-icn_double-right-arrow::before,
.cb-dbl-right::before {
    content: "\e911";
}
.cb-icon-icn_email::before,
.cb-mail::before {
    content: "\e928";
}
.cb-icon-icn_fax::before,
.cb-fax::before {
    content: "\e91c";
}
.cb-icon-icn_home::before,
.cb-home::before {
    content: "\e91f";
}
.cb-icon-icn_location::before,
.cb-location::before {
    content: "\e926";
}
.cb-icon-icn_phone::before,
.cb-icon-icn_phone_right::before,
.cb-phone-alt::before {
    content: "\e938";
}
.cb-icon-icn_resources::before,
.cb-resources::before {
    content: "\e93f";
}
.cb-icon-icn_calendar::before,
.cb-icon-icn_calendar_circle::before,
.cb-cal-full::before {
    content: "\e907";
}
.cb-icon-round-arrow::before,
.cb-east::before {
    content: "\e916";
}
.cb-icon-calendar::before,
.cb-cal-empty::before {
    content: "\e906";
}
.cb-icon-note::before,
.cb-note::before {
    content: "\e935";
}
.cb-icon-light::before,
.cb-lightbulb::before {
    content: "\e922";
}
.cb-icon-icn_question-mark::before,
.cb-icon-ssd::before,
.cb-question::before {
    content: "\e93c";
}
.cb-icon-megaphone::before,
.cb-megaphone::before {
    content: "\e92a";
}
.cb-icon-expand::before,
.cb-icon-icn_plus_round::before,
.cb-plus::before {
    content: "\e93a";
}
.cb-icon-collapse::before,
.cb-minus::before {
    content: "\e92c";
}
.cb-icon-chatbubble::before,
.cb-chat-bubble::before {
    content: "\e909";
}
.cb-icon-facebook::before,
.cb-icon-icn_facebook::before,
.cb-facebook::before {
    content: "\e91b";
}
.cb-icon-linkedin::before,
.cb-icon-icn_linkedin::before,
.cb-linkedin::before {
    content: "\e923";
}
.cb-icon-twitter::before,
.cb-icon-icn_twitter::before,
.cb-twitter::before {
    content: "\e94f";
}
.cb-icon-youtube::before,
.cb-icon-icn_youtube::before,
.cb-youtube::before {
    content: "\e956";
}
.cb-icon-icn_instagram::before,
.cb-instagram::before {
    content: "\e920";
}
.cb-icon-icn_compose::before,
.cb-compose::before {
    content: "\e90d";
}
.cb-icon-icn_out::before,
.cb-upload::before {
    content: "\e951";
}
.cb-icon-icn_list::before,
.cb-list::before {
    content: "\e924";
}
.cb-icon-icn_user::before,
.cb-icon-icn_signin::before,
.cb-user::before {
    content: "\e952";
}
.cb-icon-icn_settings::before,
.cb-settings::before {
    content: "\e944";
}
.cb-icon-icn_error::before,
.cb-error::before {
    content: "\e918";
}
.cb-icon-icn_book::before,
.cb-book::before {
    content: "\e905";
}
.cb-icon-icn_tag::before,
.cb-tag::before {
    content: "\e94c";
}
.cb-icon-icn_check::before,
.cb-check::before {
    content: "\e90b";
}
.cb-icon-icn_menu_trigger::before,
.cb-menu::before {
    content: "\e92b";
}
.cb-icon-icn_exclamation::before,
.cb-icon-icn_round_alerts::before,
.cb-icon-icn_deadline::before,
.cb-exclamation::before {
    content: "\e919";
}
.cb-icon-icn_draft-in::before,
.cb-draft-in::before {
    content: "\e915";
}
.cb-icon-icn_new-window::before,
.cb-new-window::before {
    content: "\e930";
}
.cb-icon-icn_no-draft::before,
.cb-no-draft::before {
    content: "\e931";
}
.cb-icon-Nav_Links::before,
.cb-nav-links::before {
    content: "\e92d";
}
.cb-icon-icn_quote_right::before,
.cb-quote-right::before {
    content: "\e93e";
}
.cb-icon-icn_quote_left::before,
.cb-quote-left::before {
    content: "\e93d";
}
.cb-icon-icn_close::before,
.cb-x-mark::before {
    content: "\e955";
}
.cb-icon-circle-o::before,
.cb-circle::before {
    content: "\e90c";
}
.cb-icon-circle::before,
.cb-disc::before {
    content: "\e912";
}
.cb-icon-icn_arrow_e::before,
.cb-east::before {
    content: "\e916";
}
.cb-icon-icn_arrow_ne::before,
.cb-north-east::before {
    content: "\e932";
}
.cb-icon-icn_creditcard::before,
.cb-creditcard::before {
    content: "\e90f";
}
.cb-icon-icn_educator::before,
.cb-educator::before {
    content: "\e917";
}
.cb-icon-icn_series::before,
.cb-series::before {
    content: "\e943";
}
.cb-icon-icn_ringing_bell::before,
.cb-bell::before {
    content: "\e904";
}
.cb-icon-icn_pending::before,
.cb-pending::before {
    content: "\e937";
}
.cb-icon-icn_authenticated::before,
.cb-signed-in::before {
    content: "\e946";
}
.cb-icon-icn_matrix::before,
.cb-matrix::before {
    content: "\e929";
}
.cb-icon-icn_search::before,
.cb-search::before {
    content: "\e942";
}
.cb-icon-icn_shopping_cart::before,
.cb-cart::before {
    content: "\e908";
}
.cb-icon-icn_global::before,
.cb-globe::before {
    content: "\e91e";
}
.cb-icon-Acorn::before,
.cb-acorn::before {
    content: "\e957";
}
.cb-batch-upload::before {
    content: "\e903";
}
.cb-needs-score::before {
    content: "\e92e";
}
.cb-test-scored::before {
    content: "\e94e";
}
.cb-save-date::before {
    content: "\e941";
}
.cb-north-west::before {
    content: "\e933";
}
.cb-west::before {
    content: "\e953";
}
.cb-south-west::before {
    content: "\e94a";
}
.cb-south-east::before {
    content: "\e949";
}
.cb-barchart::before {
    content: "\e901";
}
.cb-print::before {
    content: "\e93b";
}
.cb-chat::before {
    content: "\e90a";
}
.cb-window::before {
    content: "\e954";
}
.cb-bag::before {
    content: "\e900";
}
.cb-expand-window::before {
    content: "\e91a";
}
.cb-task-complete::before {
    content: "\e94d";
}
.cb-new-doc::before {
    content: "\e92f";
}
.cb-sound::before {
    content: "\e948";
}
.cb-computer::before {
    content: "\e90e";
}
.cb-share::before {
    content: "\e945";
}
.cb-lock::before {
    content: "\e927";
}
.cb-phone::before {
    content: "\e939";
}
.cb-disc-alt::before {
    content: "\e958";
}
.cb-box-fill::before {
    content: "\e959";
}
.cb-box::before {
    content: "\e95a";
}
.cb-play::before {
    content: "\e95b";
}
.cb-check-alt::before {
    content: "\e95c";
}
.cb-check-circle::before {
    content: "\e95d";
}
*/
