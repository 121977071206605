@charset "UTF-8";
/* You can add global styles to this file, and also import other style files */
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i|Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i&display=swap");
@import url("https://fonts.googleapis.com/css?family=Karla:400,400i,700,700i&display=swap");
/*!
 *  Apricot v3.4.0
 */ /*
@font-face {
    font-family: "CB Icons";
    src: url("./assets/fonts/5d02e08cb4d157c28d4c47e9106e67b3-cb-icons.eot");
    src: url("./assets/fonts/5d02e08cb4d157c28d4c47e9106e67b3-cb-icons.eot") format("embedded-opentype"),
        url("./assets/fonts/5d02e08cb4d157c28d4c47e9106e67b3-cb-icons.woff") format("woff"),
        url("./assets/fonts/5d02e08cb4d157c28d4c47e9106e67b3-cb-icons.ttf") format("truetype"),
        url("./assets/fonts/5d02e08cb4d157c28d4c47e9106e67b3-cb-icons.svg") format("svg");
}
[class^="cb-icon-"],
[class*=" cb-icon-"],
.glyphicon-cb,
.cb-glyph:not(.cb-glyph-numeric) {
    font-family: "CB Icons";
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.cb-glyph.cb-glyph-xs,
.cb-glyph-numeric.cb-glyph-xs {
    font-size: 1rem;
}
.cb-glyph.cb-glyph-sm,
.cb-glyph-numeric.cb-glyph-sm {
    font-size: -webkit-calc(1rem * 2) !important;
    font-size: calc(1rem * 2) !important;
}
.cb-glyph.cb-glyph-md,
.cb-glyph-numeric.cb-glyph-md {
    font-size: -webkit-calc(1rem * 3) !important;
    font-size: calc(1rem * 3) !important;
}
.cb-glyph.cb-glyph-lg,
.cb-glyph-numeric.cb-glyph-lg {
    font-size: -webkit-calc(1rem * 4) !important;
    font-size: calc(1rem * 4) !important;
}
.cb-glyph.cb-glyph-xl,
.cb-glyph-numeric.cb-glyph-xl {
    font-size: -webkit-calc(1rem * 6) !important;
    font-size: calc(1rem * 6) !important;
}
.cb-glyph.cb-glyph-corporate,
.cb-glyph-numeric.cb-glyph-corporate {
    color: #006298;
}
.cb-glyph.cb-glyph-corporate.cb-glyph-numeric,
.cb-glyph-numeric.cb-glyph-corporate.cb-glyph-numeric {
    border-color: #006298;
}
.cb-glyph.cb-glyph-corporate.cb-glyph-numeric.cb-glyph-circular,
.cb-glyph-numeric.cb-glyph-corporate.cb-glyph-numeric.cb-glyph-circular {
    color: #ffffff;
    border-color: #006298;
    background-color: #006298;
}
.cb-glyph.cb-glyph-corporate2,
.cb-glyph-numeric.cb-glyph-corporate2 {
    color: #0077c8;
}
.cb-glyph.cb-glyph-corporate2.cb-glyph-numeric,
.cb-glyph-numeric.cb-glyph-corporate2.cb-glyph-numeric {
    border-color: #0077c8;
}
.cb-glyph.cb-glyph-corporate2.cb-glyph-numeric.cb-glyph-circular,
.cb-glyph-numeric.cb-glyph-corporate2.cb-glyph-numeric.cb-glyph-circular {
    color: #ffffff;
    border-color: #0077c8;
    background-color: #0077c8;
}
.cb-glyph.cb-glyph-k-12,
.cb-glyph-numeric.cb-glyph-k-12 {
    color: #009cde;
}
.cb-glyph.cb-glyph-k-12.cb-glyph-numeric,
.cb-glyph-numeric.cb-glyph-k-12.cb-glyph-numeric {
    border-color: #009cde;
}
.cb-glyph.cb-glyph-k-12.cb-glyph-numeric.cb-glyph-circular,
.cb-glyph-numeric.cb-glyph-k-12.cb-glyph-numeric.cb-glyph-circular {
    color: #ffffff;
    border-color: #009cde;
    background-color: #009cde;
}
.cb-glyph.cb-glyph-higher-education,
.cb-glyph-numeric.cb-glyph-higher-education {
    color: #702f8a;
}
.cb-glyph.cb-glyph-higher-education.cb-glyph-numeric,
.cb-glyph-numeric.cb-glyph-higher-education.cb-glyph-numeric {
    border-color: #702f8a;
}
.cb-glyph.cb-glyph-higher-education.cb-glyph-numeric.cb-glyph-circular,
.cb-glyph-numeric.cb-glyph-higher-education.cb-glyph-numeric.cb-glyph-circular {
    color: #ffffff;
    border-color: #702f8a;
    background-color: #702f8a;
}
.cb-glyph.cb-glyph-access,
.cb-glyph-numeric.cb-glyph-access {
    color: #3a913f;
}
.cb-glyph.cb-glyph-access.cb-glyph-numeric,
.cb-glyph-numeric.cb-glyph-access.cb-glyph-numeric {
    border-color: #3a913f;
}
.cb-glyph.cb-glyph-access.cb-glyph-numeric.cb-glyph-circular,
.cb-glyph-numeric.cb-glyph-access.cb-glyph-numeric.cb-glyph-circular {
    color: #ffffff;
    border-color: #3a913f;
    background-color: #3a913f;
}
.cb-glyph-circular:not(.cb-glyph-numeric) {
    position: relative;
    display: block;
    height: 2em;
    width: 2em;
}
.cb-glyph-circular:not(.cb-glyph-numeric)::before {
    position: absolute;
    z-index: 2;
    left: -webkit-calc(1em / 2);
    left: calc(1em / 2);
    top: -webkit-calc(1em / 2);
    top: calc(1em / 2);
    color: #ffffff;
}
.cb-glyph-circular:not(.cb-glyph-numeric)::after {
    content: " ";
    position: absolute;
    z-index: 1;
    display: block;
    border-radius: 50%;
    height: 2em;
    width: 2em;
    background-color: #009cde;
}
.cb-glyph-circular:not(.cb-glyph-numeric).cb-glyph-corporate::after {
    background-color: #006298;
}
.cb-glyph-circular:not(.cb-glyph-numeric).cb-glyph-corporate2::after {
    background-color: #0077c8;
}
.cb-glyph-circular:not(.cb-glyph-numeric).cb-glyph-k-12::after {
    background-color: #009cde;
}
.cb-glyph-circular:not(.cb-glyph-numeric).cb-glyph-higher-education::after {
    background-color: #702f8a;
}
.cb-glyph-circular:not(.cb-glyph-numeric).cb-glyph-access::after {
    background-color: #3a913f;
}
.cb-icon-icn_online-tool::before,
.cb-online-tool::before {
    content: "\e936";
}
.cb-icon-icn_download::before,
.cb-icon-icn_download_med::before,
.cb-icon-icn_download_small::before,
.cb-icon-icn_download_res::before,
.cb-download::before {
    content: "\e914";
}
.cb-icon-icn_download_bulk::before,
.cb-batch-download::before {
    content: "\e902";
}
.cb-icon-icn_local-menu::before,
.cb-menu-local::before {
    content: "\e925";
}
.cb-icon-icn_filter::before,
.cb-filter::before {
    content: "\e91d";
}
.cb-icon-icn_sort::before,
.cb-sort::before {
    content: "\e947";
}
.cb-sort-asc::before {
    content: "\e950";
}
.cb-sort-desc::before {
    content: "\e913";
}
.cb-icon-icn_arrow-up::before,
.cb-icon-double_arrow_round::before,
.cb-icon-double_arrow::before,
.cb-up::before {
    content: "\e950";
}
.cb-icon-icn_arrow-down::before,
.cb-down::before {
    content: "\e913";
}
.cb-icon-icn_arrow-left::before,
.cb-left::before {
    content: "\e921";
}
.cb-icon-icn_arrow-right::before,
.cb-right::before {
    content: "\e940";
}
.cb-icon-icn_double-left-arrow::before,
.cb-dbl-left::before {
    content: "\e910";
}
.cb-icon-icn_double-right-arrow::before,
.cb-dbl-right::before {
    content: "\e911";
}
.cb-icon-icn_email::before,
.cb-mail::before {
    content: "\e928";
}
.cb-icon-icn_fax::before,
.cb-fax::before {
    content: "\e91c";
}
.cb-icon-icn_home::before,
.cb-home::before {
    content: "\e91f";
}
.cb-icon-icn_location::before,
.cb-location::before {
    content: "\e926";
}
.cb-icon-icn_phone::before,
.cb-icon-icn_phone_right::before,
.cb-phone-alt::before {
    content: "\e938";
}
.cb-icon-icn_resources::before,
.cb-resources::before {
    content: "\e93f";
}
.cb-icon-icn_calendar::before,
.cb-icon-icn_calendar_circle::before,
.cb-cal-full::before {
    content: "\e907";
}
.cb-icon-round-arrow::before,
.cb-east::before {
    content: "\e916";
}
.cb-icon-calendar::before,
.cb-cal-empty::before {
    content: "\e906";
}
.cb-icon-note::before,
.cb-note::before {
    content: "\e935";
}
.cb-icon-light::before,
.cb-lightbulb::before {
    content: "\e922";
}
.cb-icon-icn_question-mark::before,
.cb-icon-ssd::before,
.cb-question::before {
    content: "\e93c";
}
.cb-icon-megaphone::before,
.cb-megaphone::before {
    content: "\e92a";
}
.cb-icon-expand::before,
.cb-icon-icn_plus_round::before,
.cb-plus::before {
    content: "\e93a";
}
.cb-icon-collapse::before,
.cb-minus::before {
    content: "\e92c";
}
.cb-icon-chatbubble::before,
.cb-chat-bubble::before {
    content: "\e909";
}
.cb-icon-facebook::before,
.cb-icon-icn_facebook::before,
.cb-facebook::before {
    content: "\e91b";
}
.cb-icon-linkedin::before,
.cb-icon-icn_linkedin::before,
.cb-linkedin::before {
    content: "\e923";
}
.cb-icon-twitter::before,
.cb-icon-icn_twitter::before,
.cb-twitter::before {
    content: "\e94f";
}
.cb-icon-youtube::before,
.cb-icon-icn_youtube::before,
.cb-youtube::before {
    content: "\e956";
}
.cb-icon-icn_instagram::before,
.cb-instagram::before {
    content: "\e920";
}
.cb-icon-icn_compose::before,
.cb-compose::before {
    content: "\e90d";
}
.cb-icon-icn_out::before,
.cb-upload::before {
    content: "\e951";
}
.cb-icon-icn_list::before,
.cb-list::before {
    content: "\e924";
}
.cb-icon-icn_user::before,
.cb-icon-icn_signin::before,
.cb-user::before {
    content: "\e952";
}
.cb-icon-icn_settings::before,
.cb-settings::before {
    content: "\e944";
}
.cb-icon-icn_error::before,
.cb-error::before {
    content: "\e918";
}
.cb-icon-icn_book::before,
.cb-book::before {
    content: "\e905";
}
.cb-icon-icn_tag::before,
.cb-tag::before {
    content: "\e94c";
}
.cb-icon-icn_check::before,
.cb-check::before {
    content: "\e90b";
}
.cb-icon-icn_menu_trigger::before,
.cb-menu::before {
    content: "\e92b";
}
.cb-icon-icn_exclamation::before,
.cb-icon-icn_round_alerts::before,
.cb-icon-icn_deadline::before,
.cb-exclamation::before {
    content: "\e919";
}
.cb-icon-icn_draft-in::before,
.cb-draft-in::before {
    content: "\e915";
}
.cb-icon-icn_new-window::before,
.cb-new-window::before {
    content: "\e930";
}
.cb-icon-icn_no-draft::before,
.cb-no-draft::before {
    content: "\e931";
}
.cb-icon-Nav_Links::before,
.cb-nav-links::before {
    content: "\e92d";
}
.cb-icon-icn_quote_right::before,
.cb-quote-right::before {
    content: "\e93e";
}
.cb-icon-icn_quote_left::before,
.cb-quote-left::before {
    content: "\e93d";
}
.cb-icon-icn_close::before,
.cb-x-mark::before {
    content: "\e955";
}
.cb-icon-circle-o::before,
.cb-circle::before {
    content: "\e90c";
}
.cb-icon-circle::before,
.cb-disc::before {
    content: "\e912";
}
.cb-icon-icn_arrow_e::before,
.cb-east::before {
    content: "\e916";
}
.cb-icon-icn_arrow_ne::before,
.cb-north-east::before {
    content: "\e932";
}
.cb-icon-icn_creditcard::before,
.cb-creditcard::before {
    content: "\e90f";
}
.cb-icon-icn_educator::before,
.cb-educator::before {
    content: "\e917";
}
.cb-icon-icn_series::before,
.cb-series::before {
    content: "\e943";
}
.cb-icon-icn_ringing_bell::before,
.cb-bell::before {
    content: "\e904";
}
.cb-icon-icn_pending::before,
.cb-pending::before {
    content: "\e937";
}
.cb-icon-icn_authenticated::before,
.cb-signed-in::before {
    content: "\e946";
}
.cb-icon-icn_matrix::before,
.cb-matrix::before {
    content: "\e929";
}
.cb-icon-icn_search::before,
.cb-search::before {
    content: "\e942";
}
.cb-icon-icn_shopping_cart::before,
.cb-cart::before {
    content: "\e908";
}
.cb-icon-icn_global::before,
.cb-globe::before {
    content: "\e91e";
}
.cb-icon-Acorn::before,
.cb-acorn::before {
    content: "\e957";
}
.cb-batch-upload::before {
    content: "\e903";
}
.cb-needs-score::before {
    content: "\e92e";
}
.cb-test-scored::before {
    content: "\e94e";
}
.cb-save-date::before {
    content: "\e941";
}
.cb-north-west::before {
    content: "\e933";
}
.cb-west::before {
    content: "\e953";
}
.cb-south-west::before {
    content: "\e94a";
}
.cb-south-east::before {
    content: "\e949";
}
.cb-barchart::before {
    content: "\e901";
}
.cb-print::before {
    content: "\e93b";
}
.cb-chat::before {
    content: "\e90a";
}
.cb-window::before {
    content: "\e954";
}
.cb-bag::before {
    content: "\e900";
}
.cb-expand-window::before {
    content: "\e91a";
}
.cb-task-complete::before {
    content: "\e94d";
}
.cb-new-doc::before {
    content: "\e92f";
}
.cb-sound::before {
    content: "\e948";
}
.cb-computer::before {
    content: "\e90e";
}
.cb-share::before {
    content: "\e945";
}
.cb-lock::before {
    content: "\e927";
}
.cb-phone::before {
    content: "\e939";
}
.cb-disc-alt::before {
    content: "\e958";
}
.cb-box-fill::before {
    content: "\e959";
}
.cb-box::before {
    content: "\e95a";
}
.cb-play::before {
    content: "\e95b";
}
.cb-check-alt::before {
    content: "\e95c";
}
.cb-check-circle::before {
    content: "\e95d";
}
*/
@import "~angular-calendar/css/angular-calendar.css";
@import url("https://s3-us-west-1.amazonaws.com/ogr-icons-font/style.ogr_fonts.css");
body.body-default {
  background-color: #f7f7f7;
  background-repeat: repeat;
  font-family: var(--custom-font-family2), Sans-Serif;
}

/* Profile 1: docentes */
body.profile1,
.body_profile1 {
  background-color: #f7f7f7;
  background-repeat: repeat;
  font-family: var(--custom-font-family2), Sans-Serif;
}

/* Profile 2: estudiantes */
body.profile2 {
  background-color: #f7f7f7;
  background-repeat: repeat;
  font-family: var(--custom-font-family2), Sans-Serif;
}

body.profile3 {
  background-color: #f7f7f7;
  background-repeat: repeat;
  font-family: var(--custom-font-family2), Sans-Serif;
}

.btn_base_1 {
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  border-radius: 30px;
  -moz-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  -webkit-transition: all 0.2s ease-in;
  -ms-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
  padding: 8px 16px;
  background: #0056b5;
  border: none;
  color: white;
  font-weight: 300;
  font-family: var(--custom-font-family1), Sans-Serif;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
}
.btn_base_1:hover {
  color: white;
  text-decoration: underline;
}
.btn_base_1:focus, .btn_base_1:active {
  background: #0d3663;
  color: white;
  box-shadow: none;
}
.btn_base_1:active:focus {
  transform: scale(0.9);
  box-shadow: none;
}

.btn_base_2 {
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  border-radius: 30px;
  -moz-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  -webkit-transition: all 0.2s ease-in;
  -ms-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
  padding: 8px 16px;
  background: #bfbfbf;
  color: #444;
  border: none;
  font-weight: 300;
  font-family: var(--custom-font-family1), Sans-Serif;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
}
.btn_base_2:hover {
  background: #bfbfbf;
  color: #000000;
}
.btn_base_2:focus, .btn_base_2:active {
  background: #636363;
  color: #e6e6e6;
}
.btn_base_2:active:focus {
  transform: scale(0.9);
}

.btn_base_3 {
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  border-radius: 30px;
  -moz-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  -webkit-transition: all 0.2s ease-in;
  -ms-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
  padding: 8px 16px;
  background: #0060c7;
  border: none;
  color: white;
  font-weight: 300;
  font-family: var(--custom-font-family1), Sans-Serif;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
}
.btn_base_3:hover {
  color: white;
  text-decoration: underline;
}
.btn_base_3:focus, .btn_base_3:active {
  background: #0060c7;
  color: white;
  box-shadow: none;
}
.btn_base_3:active:focus {
  transform: scale(0.9);
  box-shadow: none;
}

.btn_base_4 {
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  border-radius: 30px;
  -moz-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  -webkit-transition: all 0.2s ease-in;
  -ms-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
  padding: 8px 16px;
  background: white;
  border: 1px solid #0060c7;
  color: #0060c7;
  font-weight: 300;
  font-family: var(--custom-font-family1), Sans-Serif;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
}
.btn_base_4:hover {
  color: #0060c7;
  text-decoration: underline;
}
.btn_base_4:focus, .btn_base_4:active, .btn_base_4.active {
  background: #0060c7;
  border: 1px solid #0060c7;
  color: white;
  box-shadow: none;
}
.btn_base_4:active:focus {
  transform: scale(0.9);
  box-shadow: none;
}

.btn-table {
  -moz-transition: all 0.1s ease-in;
  -o-transition: all 0.1s ease-in;
  -webkit-transition: all 0.1s ease-in;
  -ms-transition: all 0.1s ease-in;
  transition: all 0.1s ease-in;
  background: #0060c7;
  color: white;
  text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.3);
  border: none;
  padding: 6px;
  width: 26px;
  height: 26px;
  line-height: 14px;
  font-size: 14px;
}
.btn-table:hover {
  background: #004794;
}
.btn-table:focus {
  color: white;
}

.small-round-btn {
  background-color: #336699;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  -ms-border-radius: 0px;
  border-radius: 0px;
  -moz-transition: all 0.1s ease-in;
  -o-transition: all 0.1s ease-in;
  -webkit-transition: all 0.1s ease-in;
  -ms-transition: all 0.1s ease-in;
  transition: all 0.1s ease-in;
  display: block;
  line-height: 20px;
  width: 20px;
  height: 20px;
  display: table;
  table-layout: fixed;
  overflow: hidden;
  cursor: pointer;
  opacity: 1;
  -moz-transition: all 0.1s ease-in;
  -o-transition: all 0.1s ease-in;
  -webkit-transition: all 0.1s ease-in;
  -ms-transition: all 0.1s ease-in;
  transition: all 0.1s ease-in;
}
.small-round-btn:hover {
  opacity: 0.8;
}
.small-round-btn .small-round-btn-inner {
  display: table-row;
}
.small-round-btn a {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}

.custom_title_1,
.custom_title {
  padding: 12px 20px 18px 20px;
  color: #4e5457;
  background: url(/public/img/h2.png) no-repeat;
  background-size: 100% 100%;
  max-width: 481px;
  height: 86px;
  text-align: center;
  margin: 30px auto 20px auto;
}
.custom_title_1 h2,
.custom_title h2 {
  margin: 0;
  font-weight: normal;
  line-height: 32px;
  text-align: center;
  font-family: var(--custom-font-family1);
  font-size: 32px;
}

.custom_title_2 {
  position: relative;
  z-index: 1;
  text-align: center;
}
.custom_title_2 .ribbon {
  font-weight: normal;
  font-size: 26px;
  display: inline-block;
  max-width: 90%;
  min-width: 30%;
  font-family: var(--custom-font-family1), Sans-Serif;
  text-shadow: 1px 1px rgba(0, 0, 0, 0.4);
  position: relative;
  background: #8f7047;
  color: #fff;
  text-align: center;
  padding: 0.5em 1em; /* Adjust to suit */
  margin: 1em auto 1em;
}
.custom_title_2 .ribbon:before,
.custom_title_2 .ribbon:after {
  content: "";
  position: absolute;
  display: block;
  bottom: -0.5em;
  border: 1em solid #b59069;
  z-index: -1;
}
.custom_title_2 .ribbon:before {
  left: -2em;
  border-right-width: 2em;
  border-left-color: transparent;
}
.custom_title_2 .ribbon:after {
  right: -2em;
  border-left-width: 2em;
  border-right-color: transparent;
}
.custom_title_2 .ribbon .ribbon-content:before,
.custom_title_2 .ribbon .ribbon-content:after {
  content: "";
  position: absolute;
  display: block;
  border-style: solid;
  border-color: #96714b transparent transparent transparent;
  bottom: -0.5em;
}
.custom_title_2 .ribbon .ribbon-content:before {
  left: 0;
  border-width: 0.5em 0 0 1em;
}
.custom_title_2 .ribbon .ribbon-content:after {
  right: 0;
  border-width: 0.5em 1em 0 0;
}

.h3_sub_title_1 {
  margin: 0;
  padding: 0;
  font-size: 17px;
  color: var(--base-titlebar_subtitle_color);
  width: 100%;
  font-weight: 400;
  padding-bottom: 15px;
  margin-bottom: 10px;
  border-bottom: 1px solid #e6e6e6;
  font-family: var(--custom-font-family1), Sans-Serif;
}

.h3_sub_title_2 {
  margin: 0;
  padding: 0;
  font-size: 17px;
  color: var(--base-titlebar_subtitle_color);
  width: 100%;
  font-weight: 400;
  padding-bottom: 15px;
  margin-bottom: 10px;
  border-bottom: 1px solid #e6e6e6;
  font-family: var(--custom-font-family1), Sans-Serif;
}

.h3_sub_title_3 {
  margin: 0;
  padding: 0;
  font-size: 17px;
  color: var(--base-titlebar_subtitle_color);
  width: 100%;
  font-weight: 400;
  padding-bottom: 15px;
  margin-bottom: 10px;
  border-bottom: 1px solid #e6e6e6;
  font-family: var(--custom-font-family1), Sans-Serif;
}

.h3_sub_title_9c3 {
  margin: 0;
  padding: 0;
  font-size: 17px;
  color: var(--base-titlebar_subtitle_color);
  width: 100%;
  font-weight: 400;
  padding-bottom: 15px;
  margin-bottom: 10px;
  border-bottom: 1px solid #e6e6e6;
  font-family: var(--custom-font-family1), Sans-Serif;
}

.h4_sub_title_1 {
  font-weight: 700;
  font-size: 14px;
}

table.tabla_estadisticas tbody > tr > td,
table.tabla_estadisticas tbody > tr > th,
table.tabla_estadisticas thead > tr > th {
  padding: 4px 6px;
  vertical-align: middle;
}
table.tabla_estadisticas tbody > tr > td,
table.tabla_estadisticas tbody > tr > th {
  border: none;
}
table.tabla_estadisticas tbody > tr > td,
table.tabla_estadisticas thead > tr > th {
  text-align: center;
}
table.tabla_estadisticas tbody > tr:nth-of-type(odd) {
  background-color: #e9f5d2;
}
table.tabla_estadisticas thead > tr > th {
  border-bottom: 2px solid #c0d696;
}
table.tabla_estadisticas thead > tr > th:first-child,
table.tabla_estadisticas tbody > tr > th:first-child {
  text-align: left;
}

table.tabla_ensayos thead > tr > th,
table.tabla_planes_personales thead > tr > th {
  padding: 8px 6px;
  font-size: 13px;
  font-weight: 500;
  text-align: left;
  vertical-align: middle;
  border: 2px solid white;
  border-top: none;
  border-bottom: none;
  background-color: #fff4d2;
  color: #663300;
}
table.tabla_ensayos thead > tr > th:first-child,
table.tabla_planes_personales thead > tr > th:first-child {
  border-left: none;
  border-radius: 6px 0 0 0;
}
table.tabla_ensayos thead > tr > th:last-child,
table.tabla_planes_personales thead > tr > th:last-child {
  border-right: none;
  border-radius: 0 6px 0 0;
}
table.tabla_ensayos thead > tr > th.actions,
table.tabla_planes_personales thead > tr > th.actions {
  width: 1%;
}
table.tabla_ensayos tbody > tr > td,
table.tabla_planes_personales tbody > tr > td {
  padding: 5px;
  font-size: 12px;
  text-align: left;
  vertical-align: middle;
  border: none;
}
table.tabla_ensayos tbody > tr > td.actions,
table.tabla_planes_personales tbody > tr > td.actions {
  white-space: nowrap;
  overflow: hidden;
  text-align: center;
}
table.tabla_ensayos tbody > tr:nth-of-type(even),
table.tabla_planes_personales tbody > tr:nth-of-type(even) {
  background-color: #f1f1f1;
}
table.tabla_ensayos tbody > tr > td.min-size,
table.tabla_ensayos thead > tr > th.min-size,
table.tabla_planes_personales tbody > tr > td.min-size,
table.tabla_planes_personales thead > tr > th.min-size {
  width: 1%;
  text-align: center;
}
table.tabla_ensayos tbody > tr > th.acciones,
table.tabla_ensayos tbody > tr > td.acciones,
table.tabla_planes_personales tbody > tr > th.acciones,
table.tabla_planes_personales tbody > tr > td.acciones {
  width: 1%;
  text-align: center;
}
table.tabla_ensayos tbody > tr > td.correcta,
table.tabla_planes_personales tbody > tr > td.correcta {
  background-color: #d5eca9;
}
table.tabla_ensayos.color-scheme-1 thead > tr > th,
table.tabla_planes_personales.color-scheme-1 thead > tr > th {
  background-color: #fff4d2;
  color: #663300;
}
table.tabla_ensayos.color-scheme-2 thead > tr > th,
table.tabla_planes_personales.color-scheme-2 thead > tr > th {
  background-color: #d6d6d6;
  color: #5f5f5f;
}

.carousel_style_1 {
  padding-bottom: 30px;
}
.carousel_style_1 .sp-controllers {
  bottom: 0px;
}
@media screen and (min-width: 700px) {
  .carousel_style_1 .sp-arrow-left {
    left: 50%;
    margin-left: -260px;
  }
  .carousel_style_1 .sp-arrow-right {
    right: 50%;
    margin-right: -260px;
  }
}
.carousel_style_1 .circle {
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  border: none;
  background-color: #ffcc33;
  color: #663300;
  -moz-transition: all 0.1s ease-in;
  -o-transition: all 0.1s ease-in;
  -webkit-transition: all 0.1s ease-in;
  -ms-transition: all 0.1s ease-in;
  transition: all 0.1s ease-in;
}
.carousel_style_1 .circle:hover {
  background-color: #ffe38e;
}
.carousel_style_1 .circle span.circle-number {
  font-size: 16px;
  display: block;
}
.carousel_style_1 .selected-circle {
  background-color: #ffe38e;
}
.carousel_style_1 .sp-slider > li h4 {
  display: inline-block;
  font-family: var(--custom-font-family1), Sans-Serif;
  font-size: 24px;
  margin-top: 10px;
  padding: 3px 5px;
  margin-bottom: 10px;
  color: #333;
  max-width: 440px;
  background: rgba(255, 255, 255, 0.4);
}
@media screen and (max-width: 500px) {
  .carousel_style_1 .sp-slider > li h4 {
    font-size: 18px;
  }
}
.carousel_style_1 .sp-slider > li img {
  padding: 10px 0 0 0;
}

.cui-showcasebox-theme-default .showcasebox {
  border: 1px solid #e6e6e6;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
  background-color: white;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
}
.cui-showcasebox-theme-default .showcasebox .showcasebox_title {
  padding: 12px 20px;
  border-bottom: 1px solid #e6e6e6;
  margin-bottom: 10px;
}
.cui-showcasebox-theme-default .showcasebox .showcasebox_title .showcasebox_title_inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.cui-showcasebox-theme-default .showcasebox .showcasebox_title .showcasebox_title_inner .showcasebox_title_icon img {
  height: 40px;
  width: auto;
  margin-left: 5px;
}
.cui-showcasebox-theme-default .showcasebox .showcasebox_title .showcasebox_title_inner .showcasebox_title_icon .icon-container {
  width: 40px;
  height: 40px;
  background-color: #0060c7;
  margin-right: 8px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cui-showcasebox-theme-default .showcasebox .showcasebox_title .showcasebox_title_inner .showcasebox_title_icon .icon-container .icon {
  font-size: 22px;
  line-height: 22px;
  color: white;
}
.cui-showcasebox-theme-default .showcasebox .showcasebox_title .showcasebox_title_inner .showcasebox_title_text {
  font-family: var(--custom-font-family1), Sans-Serif;
  font-size: 18px;
  color: #0060c7;
}
.cui-showcasebox-theme-default .showcasebox .showcasebox_content {
  background-color: transparent;
  overflow: hidden;
  padding: 12px 20px;
}
.cui-showcasebox-theme-default .showcasebox .showcasebox_content .info_text {
  color: #1a1a1a;
  margin-bottom: 0;
}

.showcasebox_style_1 .showcasebox {
  border: 1px solid #e6e6e6;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
  background-color: white;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
}
.showcasebox_style_1 .showcasebox .showcasebox_title {
  padding: 12px 20px;
  border-bottom: 1px solid #e6e6e6;
  margin-bottom: 10px;
}
.showcasebox_style_1 .showcasebox .showcasebox_title .showcasebox_title_inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.showcasebox_style_1 .showcasebox .showcasebox_title .showcasebox_title_inner .showcasebox_title_icon img {
  height: 40px;
  width: auto;
  margin-left: 5px;
}
.showcasebox_style_1 .showcasebox .showcasebox_title .showcasebox_title_inner .showcasebox_title_icon .icon-container {
  width: 40px;
  height: 40px;
  background-color: #0060c7;
  margin-right: 8px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.showcasebox_style_1 .showcasebox .showcasebox_title .showcasebox_title_inner .showcasebox_title_icon .icon-container .icon {
  font-size: 22px;
  line-height: 22px;
  color: white;
}
.showcasebox_style_1 .showcasebox .showcasebox_title .showcasebox_title_inner .showcasebox_title_text {
  font-family: var(--custom-font-family1), Sans-Serif;
  font-size: 18px;
  color: #0060c7;
}
.showcasebox_style_1 .showcasebox .showcasebox_content {
  background-color: transparent;
  overflow: hidden;
  padding: 12px 20px;
}
.showcasebox_style_1 .showcasebox .showcasebox_content .info_text {
  color: #1a1a1a;
  margin-bottom: 0;
}

.showcasebox_style_1_carreras .showcasebox {
  border: 1px solid #e6e6e6;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
  background-color: white;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
}
.showcasebox_style_1_carreras .showcasebox .showcasebox_title {
  padding: 12px 20px;
  border-bottom: 1px solid #e6e6e6;
  margin-bottom: 10px;
}
.showcasebox_style_1_carreras .showcasebox .showcasebox_title .showcasebox_title_inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.showcasebox_style_1_carreras .showcasebox .showcasebox_title .showcasebox_title_inner .showcasebox_title_icon img {
  height: 40px;
  width: auto;
  margin-left: 5px;
}
.showcasebox_style_1_carreras .showcasebox .showcasebox_title .showcasebox_title_inner .showcasebox_title_icon .icon-container {
  width: 40px;
  height: 40px;
  background-color: var(--palette-colors-carreras, var(--palette-colors-primary));
  margin-right: 8px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.showcasebox_style_1_carreras .showcasebox .showcasebox_title .showcasebox_title_inner .showcasebox_title_icon .icon-container .icon {
  font-size: 22px;
  line-height: 22px;
  color: white;
}
.showcasebox_style_1_carreras .showcasebox .showcasebox_title .showcasebox_title_inner .showcasebox_title_text {
  font-family: var(--custom-font-family1), Sans-Serif;
  font-size: 18px;
  color: var(--palette-colors-carreras, var(--palette-colors-primary));
}
.showcasebox_style_1_carreras .showcasebox .showcasebox_content {
  background-color: transparent;
  overflow: hidden;
  padding: 12px 20px;
}
.showcasebox_style_1_carreras .showcasebox .showcasebox_content .info_text {
  color: #1a1a1a;
  margin-bottom: 0;
}

.showcasebox_style_1_primary .showcasebox {
  border: 1px solid #e6e6e6;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
  background-color: white;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
}
.showcasebox_style_1_primary .showcasebox .showcasebox_title {
  padding: 12px 20px;
  border-bottom: 1px solid #e6e6e6;
  margin-bottom: 10px;
}
.showcasebox_style_1_primary .showcasebox .showcasebox_title .showcasebox_title_inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.showcasebox_style_1_primary .showcasebox .showcasebox_title .showcasebox_title_inner .showcasebox_title_icon img {
  height: 40px;
  width: auto;
  margin-left: 5px;
}
.showcasebox_style_1_primary .showcasebox .showcasebox_title .showcasebox_title_inner .showcasebox_title_icon .icon-container {
  width: 40px;
  height: 40px;
  background-color: var(--palette-colors-primary, var(--palette-colors-primary));
  margin-right: 8px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.showcasebox_style_1_primary .showcasebox .showcasebox_title .showcasebox_title_inner .showcasebox_title_icon .icon-container .icon {
  font-size: 22px;
  line-height: 22px;
  color: white;
}
.showcasebox_style_1_primary .showcasebox .showcasebox_title .showcasebox_title_inner .showcasebox_title_text {
  font-family: var(--custom-font-family1), Sans-Serif;
  font-size: 18px;
  color: var(--palette-colors-primary, var(--palette-colors-primary));
}
.showcasebox_style_1_primary .showcasebox .showcasebox_content {
  background-color: transparent;
  overflow: hidden;
  padding: 12px 20px;
}
.showcasebox_style_1_primary .showcasebox .showcasebox_content .info_text {
  color: #1a1a1a;
  margin-bottom: 0;
}

.showcasebox_style_1_establecimientos .showcasebox {
  border: 1px solid #e6e6e6;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
  background-color: white;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
}
.showcasebox_style_1_establecimientos .showcasebox .showcasebox_title {
  padding: 12px 20px;
  border-bottom: 1px solid #e6e6e6;
  margin-bottom: 10px;
}
.showcasebox_style_1_establecimientos .showcasebox .showcasebox_title .showcasebox_title_inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.showcasebox_style_1_establecimientos .showcasebox .showcasebox_title .showcasebox_title_inner .showcasebox_title_icon img {
  height: 40px;
  width: auto;
  margin-left: 5px;
}
.showcasebox_style_1_establecimientos .showcasebox .showcasebox_title .showcasebox_title_inner .showcasebox_title_icon .icon-container {
  width: 40px;
  height: 40px;
  background-color: var(--palette-colors-establecimientos, var(--palette-colors-primary));
  margin-right: 8px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.showcasebox_style_1_establecimientos .showcasebox .showcasebox_title .showcasebox_title_inner .showcasebox_title_icon .icon-container .icon {
  font-size: 22px;
  line-height: 22px;
  color: white;
}
.showcasebox_style_1_establecimientos .showcasebox .showcasebox_title .showcasebox_title_inner .showcasebox_title_text {
  font-family: var(--custom-font-family1), Sans-Serif;
  font-size: 18px;
  color: var(--palette-colors-establecimientos, var(--palette-colors-primary));
}
.showcasebox_style_1_establecimientos .showcasebox .showcasebox_content {
  background-color: transparent;
  overflow: hidden;
  padding: 12px 20px;
}
.showcasebox_style_1_establecimientos .showcasebox .showcasebox_content .info_text {
  color: #1a1a1a;
  margin-bottom: 0;
}

.showcasebox_style_1_blog .showcasebox {
  border: 1px solid #e6e6e6;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
  background-color: white;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
}
.showcasebox_style_1_blog .showcasebox .showcasebox_title {
  padding: 12px 20px;
  border-bottom: 1px solid #e6e6e6;
  margin-bottom: 10px;
}
.showcasebox_style_1_blog .showcasebox .showcasebox_title .showcasebox_title_inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.showcasebox_style_1_blog .showcasebox .showcasebox_title .showcasebox_title_inner .showcasebox_title_icon img {
  height: 40px;
  width: auto;
  margin-left: 5px;
}
.showcasebox_style_1_blog .showcasebox .showcasebox_title .showcasebox_title_inner .showcasebox_title_icon .icon-container {
  width: 40px;
  height: 40px;
  background-color: var(--palette-colors-blog, var(--palette-colors-primary));
  margin-right: 8px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.showcasebox_style_1_blog .showcasebox .showcasebox_title .showcasebox_title_inner .showcasebox_title_icon .icon-container .icon {
  font-size: 22px;
  line-height: 22px;
  color: white;
}
.showcasebox_style_1_blog .showcasebox .showcasebox_title .showcasebox_title_inner .showcasebox_title_text {
  font-family: var(--custom-font-family1), Sans-Serif;
  font-size: 18px;
  color: var(--palette-colors-blog, var(--palette-colors-primary));
}
.showcasebox_style_1_blog .showcasebox .showcasebox_content {
  background-color: transparent;
  overflow: hidden;
  padding: 12px 20px;
}
.showcasebox_style_1_blog .showcasebox .showcasebox_content .info_text {
  color: #1a1a1a;
  margin-bottom: 0;
}

.showcasebox_style_1_ranking .showcasebox {
  border: 1px solid #e6e6e6;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
  background-color: white;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
}
.showcasebox_style_1_ranking .showcasebox .showcasebox_title {
  padding: 12px 20px;
  border-bottom: 1px solid #e6e6e6;
  margin-bottom: 10px;
}
.showcasebox_style_1_ranking .showcasebox .showcasebox_title .showcasebox_title_inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.showcasebox_style_1_ranking .showcasebox .showcasebox_title .showcasebox_title_inner .showcasebox_title_icon img {
  height: 40px;
  width: auto;
  margin-left: 5px;
}
.showcasebox_style_1_ranking .showcasebox .showcasebox_title .showcasebox_title_inner .showcasebox_title_icon .icon-container {
  width: 40px;
  height: 40px;
  background-color: #e6b74c;
  margin-right: 8px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.showcasebox_style_1_ranking .showcasebox .showcasebox_title .showcasebox_title_inner .showcasebox_title_icon .icon-container .icon {
  font-size: 22px;
  line-height: 22px;
  color: white;
}
.showcasebox_style_1_ranking .showcasebox .showcasebox_title .showcasebox_title_inner .showcasebox_title_text {
  font-family: var(--custom-font-family1), Sans-Serif;
  font-size: 18px;
  color: #e6b74c;
}
.showcasebox_style_1_ranking .showcasebox .showcasebox_content {
  background-color: transparent;
  overflow: hidden;
  padding: 12px 20px;
}
.showcasebox_style_1_ranking .showcasebox .showcasebox_content .info_text {
  color: #1a1a1a;
  margin-bottom: 0;
}

.showcasebox_style_2 .showcasebox {
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
  background-color: white;
  border-radius: 3px;
  overflow: hidden;
  padding: 8px;
}

.showcasebox_style_user .btn_style {
  font-size: 12px;
}
.showcasebox_style_user .left_column {
  float: left;
  padding-right: 5px;
}
.showcasebox_style_user .right_column {
  margin-left: 85px;
  padding-left: 5px;
}
.showcasebox_style_user .user-circle-container {
  background-color: #5fb9e6;
  color: white;
  border-radius: 50%;
  overflow: hidden;
  text-align: center;
  padding: 0;
  min-height: auto;
  height: 80px;
  width: 80px;
}
.showcasebox_style_user .user-circle-container .fa-placeholder .fa {
  font-size: 80px;
  padding-top: 6px;
}
.showcasebox_style_user .user-circle-container img.avatar {
  width: 100%;
  height: 100%;
}
.showcasebox_style_user h4 {
  font-family: var(--custom-font-family1), Sans-Serif;
  font-size: 20px;
  border-bottom: 2px solid #0060c7;
  width: 100%;
  padding-bottom: 5px;
  margin-bottom: 10px;
  color: #5b5b5f;
}
.showcasebox_style_user .email {
  margin-bottom: 15px;
  color: #5b5b5f;
}
.showcasebox_style_user .email fa {
  color: #5b5b5f;
  font-size: 110%;
  opacity: 0.4;
}
.showcasebox_style_user .profile-link {
  display: inline-block;
  margin-bottom: 8px;
  font-size: 13px;
}
.showcasebox_style_user .profile-link:hover {
  text-decoration: none;
}
.showcasebox_style_user .edit-link {
  display: inline-block;
  font-size: 13px;
}
.showcasebox_style_user .edit-link:hover {
  text-decoration: none;
}
.showcasebox_style_user .no-email {
  margin-bottom: 15px;
  color: #848484;
  font-size: 90%;
}
.showcasebox_style_user .no-email fa {
  color: #f7cb43;
  font-size: 110%;
}
.showcasebox_style_user .clear-btn {
  margin-bottom: 5px;
}

body .modal-content {
  border: none;
}
body .modal-content .modal-header {
  border-bottom: none;
}
body .modal-content .modal-header .close {
  font-size: 34px;
  margin-top: -4px;
}
body .modal-content .modal-header .modal-title {
  color: #000000;
  font-family: var(--custom-font-family1), Sans-Serif;
}

.menu_item_box {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  background-color: white;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
  position: relative;
  padding: 10px;
  display: block;
  color: #333;
}
@media screen and (max-width: 500px) {
  .menu_item_box {
    margin-bottom: 20px;
  }
}
.menu_item_box:hover {
  text-decoration: none;
}
.menu_item_box:focus {
  outline: none;
  text-decoration: none;
}
.menu_item_box:active {
  box-shadow: inset 1px 1px 1px rgba(0, 0, 0, 0.1);
}
.menu_item_box .menu_item_box_wrapper {
  display: table;
  width: 100%;
  table-layout: fixed;
}
.menu_item_box .menu_item_row_wrapper {
  display: table-row;
}
.menu_item_box .menu_item_box_icon_container {
  display: table-cell;
  width: 100px;
  vertical-align: top;
  padding-right: 10px;
}
@media screen and (max-width: 500px) {
  .menu_item_box .menu_item_box_icon_container {
    width: 70px;
  }
}
.menu_item_box .menu_item_box_icon_container .menu_item_box_icon {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  background-color: #f1f1f1;
  overflow: hidden;
  width: 90px;
  height: 90px;
  text-align: center;
}
.menu_item_box .menu_item_box_icon_container .menu_item_box_icon .icono {
  font-size: 62px;
  margin-top: 14px;
  color: silver;
}
@media screen and (max-width: 500px) {
  .menu_item_box .menu_item_box_icon_container .menu_item_box_icon {
    width: 60px;
    height: 60px;
  }
  .menu_item_box .menu_item_box_icon_container .menu_item_box_icon .icono {
    font-size: 44px;
    margin-top: 8px;
  }
}
.menu_item_box .menu_item_box_body {
  display: table-cell;
  vertical-align: top;
  padding-bottom: 40px;
  position: relative;
}
.menu_item_box .menu_item_box_body .menu_item_box_title {
  margin: 0 0 10px;
  margin: 0;
  padding: 0;
  font-size: 17px;
  color: var(--base-titlebar_subtitle_color);
  width: 100%;
  font-weight: 400;
  padding-bottom: 15px;
  margin-bottom: 10px;
  border-bottom: 1px solid #e6e6e6;
  font-family: var(--custom-font-family1), Sans-Serif;
  display: inline-block;
  padding-bottom: 5px;
  margin-bottom: 5px;
  width: auto;
}
.menu_item_box .menu_item_box_body .menu_item_box_text {
  color: #898989;
}
.menu_item_box .menu_item_box_link {
  position: absolute;
  bottom: 10px;
  text-align: right;
  right: 10px;
  line-height: 26px;
}
.menu_item_box .menu_item_box_link .arrow {
  font-size: 20px;
  vertical-align: middle;
  margin-left: 5px;
  color: #9c3;
}
.menu_item_box.locked {
  cursor: default;
}
.menu_item_box.locked .menu_item_box_wrapper,
.menu_item_box.locked .menu_item_box_link {
  filter: blur(2px);
}
.menu_item_box.locked .locked-menu-item {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 8px;
  -webkit-border-radius: 3px 3px 0 0;
  -moz-border-radius: 3px 3px 0 0;
  -ms-border-radius: 3px 3px 0 0;
  border-radius: 3px 3px 0 0;
  background-color: rgba(39, 145, 197, 0.7);
  color: white;
  text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.15);
}
.menu_item_box.locked .locked-menu-item .padlock {
  font-size: 30px;
  float: left;
  margin-right: 10px;
}
.menu_item_box.locked .locked-menu-item .locked-info {
  margin: 0;
  margin-left: 30px;
  display: block;
}

nav.menu-asignaturas ul > li.current-asignatura > a {
  color: white !important;
}
nav.menu-asignaturas ul > li.current-asignatura > a > .icono {
  color: white !important;
}
nav.menu-asignaturas ul > li.inactive > a:hover {
  color: white !important;
}
nav.menu-asignaturas ul > li.inactive > a:hover > .icono {
  color: white !important;
}
nav.menu-asignaturas.dropdownMode ul > li.current-asignatura > a {
  color: inherit !important;
}
nav.menu-asignaturas.dropdownMode ul > li.current-asignatura > a > .icono {
  color: inherit !important;
}
nav.menu-asignaturas.dropdownMode ul > li.current-asignatura > a:hover, nav.menu-asignaturas.dropdownMode.current-asignatura:focus {
  color: white !important;
}
nav.menu-asignaturas.dropdownMode ul > li.current-asignatura > a:hover > .icono, nav.menu-asignaturas.dropdownMode.current-asignatura:focus > .icono {
  color: white !important;
}

nav.navbar ul.nav > li > a {
  color: white;
}
nav.navbar ul.nav > li > a:hover {
  background-color: rgba(255, 255, 255, 0.1);
  color: white;
}

:root {
  --background-color-profile1: #f7f7f7;
  --background-color-navbar-profile1: #0060c7;
  --font-color-navbar-profile1: white;
  --font-color-hover-navbar-profile1: white;
  --responsive-toggle-button-background-color: transparent;
  --responsive-toggle-button-font-color: black;
  --color-omitidas: #c0c0c0;
  --color-correctas: #7e9f3e;
  --color-incorrectas: #ea6767;
  --palette-colors-primary: #0060c7;
  --palette-colors-primary-h: 211.0552763819deg;
  --palette-colors-primary-s: 100%;
  --palette-colors-primary-l: 39.0196078431%;
  --palette-colors-primary-r: 0;
  --palette-colors-primary-g: 96;
  --palette-colors-primary-b: 199;
  --palette-colors-primario: #0060c7;
  --palette-colors-primario-h: 211.0552763819deg;
  --palette-colors-primario-s: 100%;
  --palette-colors-primario-l: 39.0196078431%;
  --palette-colors-primario-r: 0;
  --palette-colors-primario-g: 96;
  --palette-colors-primario-b: 199;
  --palette-colors-background: #f7f7f7;
  --palette-colors-background-h: 0deg;
  --palette-colors-background-s: 0%;
  --palette-colors-background-l: 96.862745098%;
  --palette-colors-background-r: 247;
  --palette-colors-background-g: 247;
  --palette-colors-background-b: 247;
  --palette-colors-fontColor: #1a1a1a;
  --palette-colors-fontColor-h: 0deg;
  --palette-colors-fontColor-s: 0%;
  --palette-colors-fontColor-l: 10.1960784314%;
  --palette-colors-fontColor-r: 26;
  --palette-colors-fontColor-g: 26;
  --palette-colors-fontColor-b: 26;
  --palette-colors-fontColorOverSecondary: white;
  --palette-colors-fontColorOverSecondary-h: 0deg;
  --palette-colors-fontColorOverSecondary-s: 0%;
  --palette-colors-fontColorOverSecondary-l: 100%;
  --palette-colors-fontColorOverSecondary-r: 255;
  --palette-colors-fontColorOverSecondary-g: 255;
  --palette-colors-fontColorOverSecondary-b: 255;
  --palette-colors-lighterFontColor: #595959;
  --palette-colors-lighterFontColor-h: 0deg;
  --palette-colors-lighterFontColor-s: 0%;
  --palette-colors-lighterFontColor-l: 34.9019607843%;
  --palette-colors-lighterFontColor-r: 89;
  --palette-colors-lighterFontColor-g: 89;
  --palette-colors-lighterFontColor-b: 89;
  --palette-colors-noticias: #7faf5b;
  --palette-colors-noticias-h: 94.2857142857deg;
  --palette-colors-noticias-s: 34.4262295082%;
  --palette-colors-noticias-l: 52.1568627451%;
  --palette-colors-noticias-r: 127;
  --palette-colors-noticias-g: 175;
  --palette-colors-noticias-b: 91;
  --palette-colors-establecimientos: #309fb2;
  --palette-colors-establecimientos-h: 188.7692307692deg;
  --palette-colors-establecimientos-s: 57.5221238938%;
  --palette-colors-establecimientos-l: 44.3137254902%;
  --palette-colors-establecimientos-r: 48;
  --palette-colors-establecimientos-g: 159;
  --palette-colors-establecimientos-b: 178;
  --palette-colors-darkBackground: #221f1f;
  --palette-colors-darkBackground-h: 0deg;
  --palette-colors-darkBackground-s: 4.6153846154%;
  --palette-colors-darkBackground-l: 12.7450980392%;
  --palette-colors-darkBackground-r: 34;
  --palette-colors-darkBackground-g: 31;
  --palette-colors-darkBackground-b: 31;
  --palette-colors-secondary: #a322f2;
  --palette-colors-secondary-h: 277.2115384615deg;
  --palette-colors-secondary-s: 88.8888888889%;
  --palette-colors-secondary-l: 54.1176470588%;
  --palette-colors-secondary-r: 163;
  --palette-colors-secondary-g: 34;
  --palette-colors-secondary-b: 242;
  --palette-colors-tertiary: #8dd800;
  --palette-colors-tertiary-h: 80.8333333333deg;
  --palette-colors-tertiary-s: 100%;
  --palette-colors-tertiary-l: 42.3529411765%;
  --palette-colors-tertiary-r: 141;
  --palette-colors-tertiary-g: 216;
  --palette-colors-tertiary-b: 0;
  --palette-colors-quaternary: #3a913f;
  --palette-colors-quaternary-h: 123.4482758621deg;
  --palette-colors-quaternary-s: 42.8571428571%;
  --palette-colors-quaternary-l: 39.8039215686%;
  --palette-colors-quaternary-r: 58;
  --palette-colors-quaternary-g: 145;
  --palette-colors-quaternary-b: 63;
  --palette-colors-accent: #fedb00;
  --palette-colors-accent-h: 51.7322834646deg;
  --palette-colors-accent-s: 100%;
  --palette-colors-accent-l: 49.8039215686%;
  --palette-colors-accent-r: 254;
  --palette-colors-accent-g: 219;
  --palette-colors-accent-b: 0;
  --palette-colors-facebook: #3c5a99;
  --palette-colors-facebook-h: 220.6451612903deg;
  --palette-colors-facebook-s: 43.661971831%;
  --palette-colors-facebook-l: 41.7647058824%;
  --palette-colors-facebook-r: 60;
  --palette-colors-facebook-g: 90;
  --palette-colors-facebook-b: 153;
  --palette-colors-instagram: #c13584;
  --palette-colors-instagram-h: 326.1428571429deg;
  --palette-colors-instagram-s: 56.9105691057%;
  --palette-colors-instagram-l: 48.2352941176%;
  --palette-colors-instagram-r: 193;
  --palette-colors-instagram-g: 53;
  --palette-colors-instagram-b: 132;
  --palette-colors-twitter: #38a1f3;
  --palette-colors-twitter-h: 206.3101604278deg;
  --palette-colors-twitter-s: 88.6255924171%;
  --palette-colors-twitter-l: 58.6274509804%;
  --palette-colors-twitter-r: 56;
  --palette-colors-twitter-g: 161;
  --palette-colors-twitter-b: 243;
  --palette-colors-linkedin: #0077b5;
  --palette-colors-linkedin-h: 200.5524861878deg;
  --palette-colors-linkedin-s: 100%;
  --palette-colors-linkedin-l: 35.4901960784%;
  --palette-colors-linkedin-r: 0;
  --palette-colors-linkedin-g: 119;
  --palette-colors-linkedin-b: 181;
  --palette-colors-youtube: #ff0000;
  --palette-colors-youtube-h: 0deg;
  --palette-colors-youtube-s: 100%;
  --palette-colors-youtube-l: 50%;
  --palette-colors-youtube-r: 255;
  --palette-colors-youtube-g: 0;
  --palette-colors-youtube-b: 0;
  --palette-colors-google: #4264f4;
  --palette-colors-google-h: 228.5393258427deg;
  --palette-colors-google-s: 89%;
  --palette-colors-google-l: 60.7843137255%;
  --palette-colors-google-r: 66;
  --palette-colors-google-g: 100;
  --palette-colors-google-b: 244;
  --footer-push-height: 460px;
  --custom-font-family3: Karla;
  --custom-font-family4: Sans serif;
  --base-landing_logo_max_width: 400px;
  --base-landing_logo_footer_height: 40px;
  --base-landing_login_align: center;
  --background-color-profile1-h: 0deg;
  --background-color-profile1-s: 0%;
  --background-color-profile1-l: 96.862745098%;
  --background-color-profile1-r: 247;
  --background-color-profile1-g: 247;
  --background-color-profile1-b: 247;
  --background-color-navbar-profile1-h: 211.0552763819deg;
  --background-color-navbar-profile1-s: 100%;
  --background-color-navbar-profile1-l: 39.0196078431%;
  --background-color-navbar-profile1-r: 0;
  --background-color-navbar-profile1-g: 96;
  --background-color-navbar-profile1-b: 199;
  --font-color-navbar-profile1-h: 0deg;
  --font-color-navbar-profile1-s: 0%;
  --font-color-navbar-profile1-l: 100%;
  --font-color-navbar-profile1-r: 255;
  --font-color-navbar-profile1-g: 255;
  --font-color-navbar-profile1-b: 255;
  --font-color-hover-navbar-profile1-h: 0deg;
  --font-color-hover-navbar-profile1-s: 0%;
  --font-color-hover-navbar-profile1-l: 100%;
  --font-color-hover-navbar-profile1-r: 255;
  --font-color-hover-navbar-profile1-g: 255;
  --font-color-hover-navbar-profile1-b: 255;
  --responsive-toggle-button-background-color-h: 0deg;
  --responsive-toggle-button-background-color-s: 0%;
  --responsive-toggle-button-background-color-l: 0%;
  --responsive-toggle-button-background-color-r: 0;
  --responsive-toggle-button-background-color-g: 0;
  --responsive-toggle-button-background-color-b: 0;
  --responsive-toggle-button-font-color-h: 0deg;
  --responsive-toggle-button-font-color-s: 0%;
  --responsive-toggle-button-font-color-l: 0%;
  --responsive-toggle-button-font-color-r: 0;
  --responsive-toggle-button-font-color-g: 0;
  --responsive-toggle-button-font-color-b: 0;
  --color-omitidas-h: 0deg;
  --color-omitidas-s: 0%;
  --color-omitidas-l: 75.2941176471%;
  --color-omitidas-r: 192;
  --color-omitidas-g: 192;
  --color-omitidas-b: 192;
  --color-correctas-h: 80.412371134deg;
  --color-correctas-s: 43.8914027149%;
  --color-correctas-l: 43.3333333333%;
  --color-correctas-r: 126;
  --color-correctas-g: 159;
  --color-correctas-b: 62;
  --color-incorrectas-h: 0deg;
  --color-incorrectas-s: 75.7225433526%;
  --color-incorrectas-l: 66.0784313725%;
  --color-incorrectas-r: 234;
  --color-incorrectas-g: 103;
  --color-incorrectas-b: 103;
}

/* ############################################## 

Código loader Spinner Puntaje fuera de la aplicación

############################################## */
.outside-loader {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 60px;
  height: 60px;
}

.spinner {
  margin: 0 auto;
  font-size: 8px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(169, 169, 169, 0.2);
  border-right: 1.1em solid rgba(169, 169, 169, 0.2);
  border-bottom: 1.1em solid rgba(169, 169, 169, 0.2);
  border-left: 1.1em solid #3399ff;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: spin 1.1s infinite linear, color-puntaje-outside 3s infinite linear;
  animation: spin 1.1s infinite linear, color-puntaje-outside 3s infinite linear;
}

.spinner,
.spinner:after {
  border-radius: 50%;
  width: 60px;
  height: 60px;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes color-puntaje-outside {
  0% {
    border-left: 1.1em solid #3399ff;
  }
  20% {
    border-left: 1.1em solid #9c3;
  }
  40% {
    border-left: 1.1em solid #fc0;
  }
  60% {
    border-left: 1.1em solid #f90;
  }
  80% {
    border-left: 1.1em solid #f33;
  }
  100% {
    border-left: 1.1em solid #3399ff;
  }
}
@keyframes color-puntaje-outside {
  0% {
    border-left: 1.1em solid #3399ff;
  }
  20% {
    border-left: 1.1em solid #9c3;
  }
  40% {
    border-left: 1.1em solid #fc0;
  }
  60% {
    border-left: 1.1em solid #f90;
  }
  80% {
    border-left: 1.1em solid #f33;
  }
  100% {
    border-left: 1.1em solid #3399ff;
  }
}
html {
  display: block;
  height: 100%;
  font-size: 16px;
}

puntaje {
  display: block;
  height: 100%;
}

body {
  display: block;
  height: 100%;
}

@-webkit-keyframes fadingBody {
  0%, 80% {
    opacity: 0;
    background: white;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes fadingBody {
  0%, 80% {
    opacity: 0;
    background: white;
  }
  100% {
    opacity: 1;
  }
}
@-ms-keyframes fadingBody {
  0%, 80% {
    opacity: 0;
    background: white;
  }
  100% {
    opacity: 1;
  }
}
@-o-keyframes fadingBody {
  0%, 80% {
    opacity: 0;
    background: white;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadingBody {
  0%, 80% {
    opacity: 0;
    background: white;
  }
  100% {
    opacity: 1;
  }
}
@media print {
  .print-hide {
    display: none !important;
  }
  .print-show-block {
    display: block !important;
  }
  .print-page-break-after {
    page-break-after: always;
    display: block;
  }
  .print-page-break-before {
    page-break-before: always;
    display: block;
  }
  .print-page-break-avoid {
    page-break-inside: avoid;
  }
  .print-full-width {
    width: 100% !important;
  }
  .print-force-expand {
    height: auto !important;
    overflow: auto !important;
  }
}
@page {
  size: auto;
  margin: 0.5in 0.5in 0.5in 0.5in;
}
.index-hidden {
  display: none;
}

.using-mouse button:focus,
.using-mouse button:active:focus {
  outline: none;
}